import React from 'react';
import { useTheme } from '@mui/material/styles';

const LogoSvg = ({ className, styles, pathStyles = {} }) => {
  const theme = useTheme();
  const fillColor = theme.palette.mode === 'dark' ? 'white' : 'black';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 774.19 291.64"
      className={className}
      style={styles}
    >
      <g data-name="Layer 1">
        <g>
          <path
            d="M119.85.5h19.03c66.94,178.43,101.77,271.29,104.47,278.58,1.08,2.7,2.36,5.06,3.85,7.09,1.48,2.02,2.63,3.31,3.44,3.85l1.21,1.21h-43.33c.27-.27.61-.74,1.01-1.42.41-.67.81-2.29,1.21-4.86.4-2.56-.07-5.33-1.42-8.3-1.62-4.86-9.31-25.64-23.08-62.36h-15.39c-16.47,0-34.69-.13-54.66-.4-19.71,0-37.8-.13-54.26-.41h-22.27l-13.36,33.61c-4.86,12.69-9.05,23.35-12.55,31.99-1.35,3.78-2.16,6.07-2.43,6.88-.54,1.08-.75,2.02-.61,2.83.13.81.34,1.49.61,2.02l.41.81H0c2.43-1.89,3.91-3.78,4.45-5.67,1.08-2.43,2.29-5.4,3.64-8.91,3.51-8.91,7.69-19.57,12.55-31.99,11.6-29.42,24.43-61.81,38.47-97.18,13.77-35.36,26.45-67.89,38.06-97.58,4.86-12.41,9.04-23.08,12.55-31.99l2.02-4.86c0-.27-.07-.61-.2-1.01-.14-.41-.34-.74-.61-1.01-1.08-2.43-2.5-4.52-4.25-6.28-1.76-1.75-3.31-2.9-4.66-3.44l-1.62-.81c6.48-.27,12.96-.41,19.44-.41ZM183.83,208.22c-1.89-5.12-4.45-12.08-7.69-20.85-3.24-8.77-6.07-16.46-8.5-23.08-2.43-6.61-4.45-12.35-6.07-17.21-4.86-13.22-11.48-31.51-19.84-54.87-8.37-23.35-13.91-38.8-16.6-46.36-2.97-8.64-6.21-17.01-9.72-25.1-3.51,8.91-7.69,19.58-12.55,31.99-11.61,29.43-24.29,61.82-38.06,97.18l-22.68,57.5h19.84c16.46.27,34.55.41,54.26.41,19.97.27,38.19.41,54.66.41h12.96Z"
            style={{ ...pathStyles.layer1, fill: fillColor }}
          />
          <path
            d="M316.64,270.18c0,5.13.81,9.45,2.43,12.96,1.62,3.51,3.24,5.81,4.86,6.88l2.43,1.62-64.79-.41c4.31-.27,7.69-2.29,10.12-6.07,2.43-3.78,3.78-7.55,4.05-11.34l.4-5.26c0-6.48-.14-14.17-.4-23.08,0-30.23-.14-63.57-.41-100.01-.27-36.17-.54-69.37-.81-99.61,0-12.96-.14-24.02-.41-33.2v-5.26c0-1.35-.68-2.63-2.02-3.85-1.35-1.21-2.57-2.09-3.64-2.63l-2.02-.81h117.43c28.88-.81,51.29,3.38,67.22,12.55,17.27,9.72,28.61,23.48,34.01,41.3,5.4,18.9,4.72,39.55-2.02,61.95-3.51,11.61-8.78,21.26-15.79,28.95-7.02,7.69-14.98,13.1-23.89,16.2-8.91,3.11-17.08,5.13-24.5,6.07-7.43.95-14.92,1.28-22.47,1.01h-6.07l107.3,118.24c2.16,2.43,4.59,4.19,7.29,5.26h-15.79c1.62-1.35.41-4.32-3.64-8.91-4.05-4.59-12.55-14.03-25.51-28.34-12.96-14.3-20.65-22.81-23.08-25.51l-55.07-60.74c-5.4-.27-13.63-.4-24.7-.4h-41.3c.27,22.14.41,48.05.41,77.74.27,9.72.4,17.96.4,24.7ZM383.04,162.06c26.18-2.16,45.21-11.07,57.09-26.72,11.88-15.65,17.54-35.9,17.01-60.74-.54-19.16-8.17-35.09-22.88-47.78-14.72-12.69-30.03-19.57-45.96-20.65l-73.69.81v5.67c.27,8.91.41,19.84.41,32.8.27,30.24.54,63.57.81,100.01v16.2h41.3c11.6,0,20.25.14,25.91.4Z"
            style={{ ...pathStyles.layer2, fill: fillColor }}
          />
          <path
            d="M718.71.5h23.49c-2.97.81-7.16,3.64-12.55,8.5l-3.64,3.64c-5.13,5.4-11.21,11.74-18.22,19.03l-54.66,57.09-14.17,14.58,5.26,6.88c.81,1.35,1.89,3.11,3.24,5.26,3.24,4.86,7.15,10.8,11.74,17.82,11.07,16.74,23.08,35.09,36.04,55.07,12.96,19.71,24.96,37.93,36.04,54.66l7.29,10.93c1.08,1.62,1.89,2.97,2.43,4.05,4.86,7.29,10.05,13.7,15.59,19.23,5.53,5.54,9.92,9.25,13.16,11.14l4.45,3.24-8.91-.41h-3.24c-2.97,0-6.62.14-10.93.41h-32.8l-27.13-.41c.54-.27,1.21-.54,2.03-.81.81-.27,1.96-1.28,3.44-3.04,1.48-1.75,2.49-3.78,3.04-6.07.54-2.29.27-5.67-.81-10.12-1.08-4.45-3.38-9.38-6.88-14.78l-30.37-46.16c-12.96-19.97-24.84-38.2-35.63-54.66l-12.15-18.22c-.54-1.08-1.08-1.96-1.62-2.63-.54-.67-.95-1.28-1.21-1.82l-12.55,12.96c-7.02,7.29-13.1,13.63-18.22,19.03l-2.83,2.83c.27,22.14.41,48.05.41,77.74.27,11.07.4,20.52.4,28.34.27,4.05,1.28,7.56,3.04,10.53,1.75,2.97,3.3,4.86,4.66,5.67l2.43,1.62h-65.19c8.91-.81,13.77-7.55,14.58-20.25,0-7.02-.14-15.65-.4-25.91,0-30.23-.14-63.57-.41-100.01-.27-36.17-.54-69.37-.81-99.61v-22.27h-.41c0-1.62-.14-3.64-.41-6.07-.27-2.43-1.62-5.8-4.05-10.12-2.43-4.31-5.81-6.61-10.12-6.88,6.21-.27,15.39-.4,27.53-.4h37.66c-5.4,2.7-8.64,8.5-9.72,17.41v27.94c.27,30.24.54,63.57.81,100.01v13.77c3.24-3.24,8.77-9.04,16.6-17.41,16.46-17.27,34.68-36.3,54.66-57.09,19.97-20.78,38.2-39.81,54.66-57.09,3.24-3.24,6.34-6.48,9.31-9.72,2.97-3.24,5.67-6.07,8.1-8.5,0-.27.06-.4.2-.4s.2-.13.2-.4c1.62-1.89,2.29-3.44,2.02-4.66-.27-1.21-1.08-2.09-2.43-2.63l-2.03-.81Z"
            style={{ ...pathStyles.layer3, fill: fillColor }}
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoSvg;
